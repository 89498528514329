import { Typography, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

interface IProps {
  title: string;
  startIcon?: any;
  variant?: any;
  disabled?: boolean;
  fontWeight?: number;
  onClick?: (props: any) => void;
}

const DefaultBtn = ({
  title,
  startIcon,
  variant,
  disabled,
  fontWeight,
  onClick,
}: IProps) => {
  if (disabled) {
    return <GreyBtn title={title} disabled={true} />;
  }
  return (
    <StyledDefaultBtn
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon || undefined}
      role="button"
      aria-label={title}
    >
      <Typography
        component="p"
        variant={variant || "h6"}
        fontWeight={fontWeight || 500}
      >
        {title}
      </Typography>
    </StyledDefaultBtn>
  );
};

const DefaultSmallBtn = ({
  title,
  startIcon,
  variant,
  disabled,
  onClick,
}: IProps) => {
  return (
    <StyledSmallBtn
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon || undefined}
    >
      <Typography component="p" variant={variant || "subtitle2"}>
        {title}
      </Typography>
    </StyledSmallBtn>
  );
};

const GreyBtn = ({ title, startIcon, variant, disabled, onClick }: IProps) => {
  return (
    <StyledGreyBtn
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon || undefined}
    >
      <Typography component="p" variant={variant || "h6"}>
        {title}
      </Typography>
    </StyledGreyBtn>
  );
};

const ActionBtn = ({ title, startIcon, disabled, onClick }: IProps) => {
  return (
    <StyledActionButton
      color={"info"}
      variant="text"
      size="small"
      disabled={disabled}
      startIcon={startIcon || undefined}
      onClick={onClick || undefined}
      sx={{ "& svg": { fontSize: `15px !important` } }}
    >
      <Typography
        component="span"
        variant="subtitle2"
        color={disabled ? "text.disabled" : "info.dark"}
      >
        {title}
      </Typography>
    </StyledActionButton>
  );
};

const LightBtn = ({
  title,
  startIcon,
  variant,
  disabled,
  fontWeight,
  onClick,
}: IProps) => {
  const theme = useTheme();
  return (
    <Button
      size="small"
      variant="outlined"
      sx={{ bgcolor: theme.palette.info.light }}
      disabled={disabled}
      startIcon={startIcon || undefined}
    >
      <Typography
        component="span"
        variant={variant || "subtitle2"}
        color="secondary"
        fontWeight={fontWeight || 500}
        onClick={onClick}
      >
        {title}
      </Typography>
    </Button>
  );
};

const LayoutBtn = ({
  title,
  startIcon,
  variant,
  disabled,
  onClick,
}: IProps) => {
  return (
    <StyledLayoutBtn
      size="small"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon || <></>}
    >
      <Typography component="p" variant={variant || "h6"}>
        {title}
      </Typography>
    </StyledLayoutBtn>
  );
};

const StyledDefaultBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  textTransform: "none",
  fontWeight: 600,
  // background: "linear-gradient(270deg, #c42e2e 0%, #d32f2f 100%)",
  background: theme.palette.error.main,
  color: theme.palette.primary.main,
  "&:hover": {
    "& p": { fontWeight: 700, color: theme.palette.primary.main },
    background: theme.palette.error.dark,
    borderRadius: 15,
    opacity: 1,
  },
  "& img": {
    position: "relative",
    top: 5,
    width: 20,
    height: "auto",
    [theme.breakpoints.down("md")]: { top: 0, width: 15 },
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    "& p": { fontSize: theme.typography.subtitle1.fontSize },
  },
}));

const StyledSmallBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 600,
  background: theme.palette.error.light,
  color: theme.palette.primary.main,
  paddingRight: 10,
  "&:hover": {
    "& p": { fontWeight: 700, color: theme.palette.primary.main },
    background: theme.palette.error.main,
    borderRadius: 2.5,
  },
  "& img": {
    position: "relative",
    top: 5,
    width: 20,
    height: "auto",
    [theme.breakpoints.down("md")]: { top: 0, width: 15 },
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    "& p": { fontSize: theme.typography.caption.fontSize },
  },
}));

const StyledGreyBtn = styled(Button)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  textTransform: "none",
  fontWeight: 600,
  background: "#9ca3af",
  color: theme.palette.primary.main,
  "&:hover": {
    "& p": { fontWeight: 700, color: theme.palette.primary.main },
    background: theme.palette.error.main,
    borderRadius: 15,
    opacity: 1,
  },
  [theme.breakpoints.down("md")]: {
    "& p": { fontSize: theme.typography.subtitle1.fontSize },
  },
  "& img": {
    position: "relative",
    top: 5,
    width: 20,
    height: "auto",
    [theme.breakpoints.down("md")]: { top: 0, width: 15 },
  },
}));

const StyledLayoutBtn = styled(Button)(({ theme }) => ({
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 15,
  paddingRight: 15,
  textTransform: "none",
  fontWeight: 600,
  background: theme.palette.error.light,
  color: theme.palette.primary.main,
  "&:hover": {
    "& p": { fontWeight: 700, color: theme.palette.primary.main },
    background: theme.palette.error.main,
    borderRadius: 15,
    opacity: 1,
  },
  [theme.breakpoints.down("md")]: {
    "& p": { fontSize: theme.typography.subtitle1.fontSize },
  },
  "& img": {
    position: "relative",
    top: 5,
    width: 20,
    height: "auto",
    [theme.breakpoints.down("md")]: { top: 0, width: 15 },
  },
}));

const StyledActionButton = styled(Button)(({ theme }) => ({
  zIndex: 3,
  background: theme.palette.info.light,
  color: theme.palette.info.dark,
  [theme.breakpoints.down("sm")]: {
    "& > span": {
      fontSize: theme.typography.caption.fontSize,
      "& svg": { fontSize: `${theme.typography.caption.fontSize} !important` },
    },
  },
}));

export { LightBtn, DefaultBtn, DefaultSmallBtn, GreyBtn, LayoutBtn, ActionBtn };
