import { create } from "zustand";
import { devtools } from "zustand/middleware";
import {
  TypeOfSpace,
  TypeOfSpaceChat,
  TypeOfSpaceFeature,
  TypeOfUser,
} from "~/types";
// import { ILocalDBSpaceCard } from "../db.local";

export const initialStatus = {
  isSide: true, // 사이드 내비게이션 show/hide
  isSpaceSetting: false, // 스페이스 설정 show/hide
  isBoardSetting: false, // 보드 설정 show/hide
  isSpaceHistory: false, // 스페이스 기록 show/hide
  cardHistory: "" as TypeOfSpace.SpaceCard, // 카드 기록 show/hide
  isSpaceInfo: false, // 스페이스 정보 show/hide
  isSpaceNotice: false, // 스페이스 공지사항 show/hide
  isSpaceShare: false, // 스페이스 공유 show/hide
  isSpaceSlide: false, // 스페이스 발표 show/hide
  isSpaceDashboard: false, // 스페이스 현황 show/hide
  isPDFSlide: false as boolean, // 스페이스 PDF 발표 show/hide with pdf uploader management
  isCardSlide: "" as string, // 스페이스 카드 발표 show/hide with
  isCardPin: false as boolean, // 카드 핀
  isSpaceChat: false, // 스페이스 채팅 show/hide
  isSpaceQuestion: false, // 스페이스 응답 리스트 show/hide
  isSpaceQuestionBuilder: "" as "" | "create" | "update", // 스페이스 응답 빌더 show/hide & 추가, 삭제 구분
  isSpaceExcelExporter: false as boolean, // 스페이스 보드, 카드 정보 엑셀 내보내기
  isCardDiscuss: false as boolean, // 카드 논의 show/hide
  cardDiscuss: null as any, // 카드 논의
  spaceOnlineMember: [] as any[], // 스페이스에 온라인인 멤버 확인
  formImage: { url: "", file: null } as any, // 질문 설명에 이미지 첨부하기
  spaceQuestionBuilderOption:
    null as TypeOfSpaceFeature.SpaceQuestionDoc | null, // builder option 값 저장
  scrollTo: null as { type: string; id: string } | null, // scrollTo 보드 추가 시에
  isEditorFocus: false, // 카드 에디터 포커스 여부(모바일 처리를 위해 사용)
  isSpaceEditorSave: false as boolean, // 카드 에디터 저장 요청을 위해 (key 값이 변해서 날아감...!)
  isSpaceDrawing: false as boolean, // 카드 에디터 드로잉 켜져있는지 확인
  isSpaceSlideExportMode: false as boolean, // 스페이스 슬라이드 exportMode 켜졌는지 확인
  isSpaceFullscreen: false as boolean, // 스페이스 보드 풀스크린 모드
  spaceCardCopyID: null as {
    cid: string;
    bid: string;
    graphic: TypeOfSpace.BoardGraphicType;
  } | null, // 카드 복제용 cid
  isSpaceEnterDialog: false as boolean, // 스페이스 접속 팝업
  isSpacePinDialog: false as boolean, // 스페이스 핀코드 수정 팝업
  isSpaceGroupDialog: false as boolean, // 스페이스 그룹 수정 팝업
  spaceList: {
    data: [],
    latest: [],
  } as {
    data: TypeOfSpace.Space[] | []; // 내가 만든 스페이스 리스트
    latest: TypeOfSpace.Space[] | []; // 최근 접속 스페이스 리스트
  }, // 스페이스 리스트 검색
  spaceSearchList: {
    data: [],
    latest: [],
  } as {
    data: TypeOfSpace.Space[] | [];
    latest: TypeOfSpace.Space[] | [];
  }, // 스페이스 리스트 검색 결과
  spaceCardList: null as TypeOfSpace.SpaceColumnsBoard[] | null, // v2 스페이스 카드 리스트 리스닝

  selectedSpace: null as TypeOfSpace.Space | null, // DB Listening 스페이스
  selectedSpaceOwner: null as TypeOfUser.Profile, // 스페이스 소유자 정보 저장
  selectedSpaceColumns: [] as TypeOfSpace.SpaceColumnsBoard[] | null, // RTDB Listening 스페이스 컬럼
  selectedSpaceUsage: [] as TypeOfSpace.SpaceUsage[] | null, // RTDB Listening 스페이스 사용량
  selectedBoard: null as TypeOfSpace.SpaceColumnsBoard | null, // 선택된 스페이스 보드
  selectedCard: null as TypeOfSpace.SpaceColumnsCard | null, // 선택된 카드

  isSearchMobile: false as boolean, // 모바일용 검색바 Show/Hide
  search: null as TypeOfSpace.PermissionUser, // 검색어 카드제목|작성자
  searchList: [] as TypeOfSpace.SpaceColumnsBoard[] | null, // 스페이스 내에서 검색결과 정보

  searchOptionMine: false as boolean, // 내 카드만 보기
  searchOptionGroup: false as boolean, // 그룹 카드만 보기
  searchOptionColor: "" as string, // 색상으로 검색
  searchOptionEmoji: "" as string, // 이모지 등록 여부로 검색
  searchOptionStartDate: "" as string, // 시작일로 검색
  searchOptionEndDate: "" as string, // 종료일로 검색
  searchOptionDiscussSize: 0 as number, // 댓글 개수로 검색

  isLockAlertOpen: false as boolean, // 잠금 해제 경고 노출 여부
  card: null as TypeOfSpace.SpaceCard | null, // DB Listening 카드
  chat: null as {
    id: string;
    count: number;
    chatList: TypeOfSpaceChat.SpaceChat[];
  } | null, // DB Listening 채팅

  imageZoom: "" as string, // Zoom 이미지 URL
  slide: null as TypeOfSpaceFeature.SpaceSlideType | null, // 현재 슬라이드 데이터
  formSlide: null as any, // 질문형 카드 슬라이드
  question: null as TypeOfSpaceFeature.SpaceQuestionDoc | null, // 현재 질문 데이터
  questionAnalytics: null as TypeOfSpaceFeature.SpaceQuestionDoc | null, // 현재 질문 데이터 분석기 열기

  isCardHeaderVisible: true as boolean, // 카드 헤더 보이는지 여부

  isSpaceEvaluate: false as boolean, // 유저 활동 정보 평가 show/hide
  isSpaceAttach: false as boolean, // 스페이스 카드 내에 파일 첨부 show/hide
  isLeftAttach: false as boolean, // 첨부파일 왼쪽에서 크게보기

  isSpaceRestore: false, // 스페이스 복구 show/hide
  restoreList: [] as TypeOfSpace.SpaceColumnsBoard[] | null, // 스페이스 복구 미리보기 리스트
  isRestorePreview: {
    index: 0,
    create: 0,
  } as {
    index: number;
    create: number;
  }, // 스페이스 복구 미리보기 시점

  isCardRestore: false, // 카드 복구 show/hide
  fileViewer: null as TypeOfSpace.SpaceCardAttach | null, // 카드 파일 크게 보기

  isSpaceViewer: false as boolean, // 스페이스 뷰어 모드 접속
  isSpaceInsight: false as boolean, // 학습자용 스페이스 인사이트
  isSpaceVote: "" as string, // 스페이스 투표함 에디터 show/hide & update id
  spaceVoteList: [] as TypeOfSpaceFeature.Vote[], // 스페이스 투표 정보
  spaceVote: null as TypeOfSpaceFeature.Vote, // 투표하기
  spaceVoteViewer: null as TypeOfSpaceFeature.Vote, // 투표결과 상세

  // isArtEdit: false, // 아트 editor show/hide
  // artData: null, // 아트(fabric type)
  // artHistory: [], // 아트 기록(fabric type)

  spaceOwnList: [] as any[], // 스페이스 소유 리스트
  boardOwnList: null as { [key: string]: any[] }, // 스페이스별 보드 소유 리스트

  isLeftCardViewer: false as boolean, // 지금까지의 카드 작성 기록 보기 show/hide
  // NOTE: 지금까지의 카드 작성 기록 보기 정보 저장 형태로 수정
  leftCardViewer: {
    uid: "",
    exceptCards: [],
  } as {
    uid: string;
    exceptCards: any[];
  },
  isEmbedCard: {
    src: "",
    isShow: false,
  }, // 카드 첨부
  isFullScreen: false, // 카드 풀스크린 여부
  isSideFullscreen: false, // 카드 좌측보기 풀스크린 여부
  isMembersGroupDialog: false, // 구성원의 직접 그룹 생성
};

const store = () => initialStatus;

const useSpaceStore = create(import.meta.env.DEV ? devtools(store) : store);

export default useSpaceStore;
