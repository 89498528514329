// REF:
// - https://velog.io/@hke2/React-%EC%B1%84%EB%84%90%ED%86%A1-%EC%97%B0%EB%8F%99%ED%95%98%EA%B8%B0
// - https://velog.io/@eunbani/2022-08-05-TIL-%EB%A6%AC%EC%95%A1%ED%8A%B8%EC%97%90-%EC%B1%84%EB%84%90%ED%86%A1-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0

interface IChannelIO {
  c?: (...args: any) => void;
  q?: [methodName: string, ...args: any[]][];
  (...args: any): void;
}

interface BootOption {
  appearance?: string;
  customLauncherSelector?: string;
  hideChannelButtonOnBoot?: boolean;
  hidePopup?: boolean;
  language?: string;
  memberHash?: string;
  memberId?: string;
  pluginKey: string;
  profile?: Profile;
  trackDefaultEvent?: boolean;
  trackUtmSource?: boolean;
  unsubscribe?: boolean;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
  zIndex?: number;
}

interface Callback {
  (error: Error | null, user: CallbackUser | null): void;
}

interface CallbackUser {
  alert: number;
  avatarUrl: string;
  id: string;
  language: string;
  memberId: string;
  name?: string;
  profile?: Profile | null;
  tags?: string[] | null;
  unsubscribeEmail: boolean;
  unsubscribeTexting: boolean;
}

interface UpdateUserInfo {
  language?: string;
  profile?: Profile | null;
  profileOnce?: Profile;
  tags?: string[] | null;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
}

interface Profile {
  [key: string]: string | number | boolean | null | undefined;
}

interface FollowUpProfile {
  name?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
}

interface EventProperty {
  [key: string]: string | number | boolean | null | undefined;
}

type Appearance = "light" | "dark" | "system" | null;

class ChannelService {
  constructor() {
    this.loadScript();
  }

  loadScript() {
    const w = window;
    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        "ChannelIO script included twice."
      );
    }
    const ch = function () {
      ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;
    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }
      w.ChannelIOInitialized = true;
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
      s.charset = "UTF-8";
      const x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    }
    if (document.readyState === "complete") {
      l();
    } else if (window.attachEvent) {
      window.attachEvent("onload", l);
    } else {
      window.addEventListener("DOMContentLoaded", l, false);
      window.addEventListener("load", l, false);
    }
  }

  boot(callback) {
    window.ChannelIO(
      "boot",
      {
        pluginKey: import.meta.env.VITE_CHANNEL_IO_KEY,
        customLauncherSelector: ".button", // 커스텀 버튼의 클래스명을 작성
        hideChannelButtonOnBoot: true, // 커스텀 버튼일때 작성
        // NOTE: memberId, profile: 유저가 익명이면 작성하지 않음
        // memberId: "",
        // profile: { name: "", mobileNumber: "010-xxxx-xxxx", email: "" }
      },
      (error, user) => {
        callback(user.alert); // callback 함수에 사용자의 alert 값을 전달한다.
      }
    );
    return window.ChannelIO;
  }

  shutdown() {
    window.ChannelIO("shutdown");
  }

  showMessenger() {
    window.ChannelIO("showMessenger");
  }

  hideMessenger() {
    window.ChannelIO("hideMessenger");
  }

  openChat(chatId, message) {
    window.ChannelIO("openChat", chatId, message);
  }

  track(eventName, eventProperty) {
    window.ChannelIO("track", eventName, eventProperty);
  }

  onShowMessenger(callback) {
    window.ChannelIO("onShowMessenger", callback);
  }

  onHideMessenger(callback) {
    window.ChannelIO("onHideMessenger", callback);
  }

  onBadgeChanged(callback) {
    window.ChannelIO("onBadgeChanged", callback);
  }

  onChatCreated(callback) {
    window.ChannelIO("onChatCreated", callback);
  }

  onFollowUpChanged(callback) {
    window.ChannelIO("onFollowUpChanged", callback);
  }

  onUrlClicked(callback) {
    window.ChannelIO("onUrlClicked", callback);
  }

  clearCallbacks() {
    window.ChannelIO("clearCallbacks");
  }

  updateUser(userInfo, callback) {
    window.ChannelIO("updateUser", userInfo, callback);
  }

  addTags(tags, callback) {
    window.ChannelIO("addTags", tags, callback);
  }

  removeTags(tags, callback) {
    window.ChannelIO("removeTags", tags, callback);
  }

  setPage(page) {
    window.ChannelIO("setPage", page);
  }

  resetPage() {
    window.ChannelIO("resetPage");
  }

  showChannelButton() {
    window.ChannelIO("showChannelButton");
  }

  hideChannelButton() {
    window.ChannelIO("hideChannelButton");
  }

  setAppearance(appearance) {
    window.ChannelIO("setAppearance", appearance);
  }
}

export default new ChannelService();
