import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useUserStore } from "../store";

/**
 * - 유저 관련 API
 */

const APIUri = import.meta.env.DEV
  ? `http://${import.meta.env.VITE_DEV_HOST}:1234`
  : import.meta.env.VITE_API_SERVER;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: APIUri,
  headers: {
    "X-IMPACTUS-CLIENT": import.meta.env.VITE_SECRET_KEY,
    "Content-Type": "application/json",
    Referrer: "impactus.kr",
  },
  timeout: 10000,
});

// 이메일 주소 본인인증 메일 전송
export const userEmailSendCheck = async (email: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/send/auth`,
      { email }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 이메일 주소 본인인증 코드 대조
export const userEmailCheck = async (email: string, code: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/auth`,
      { email, code }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 이메일 주소 중복 체크
export const userEmailDuplicatedCheck = async (email: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/email-check`,
      { email }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 카카오 로그인 요청
export const userKakaoLogin = async (code: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/auth/kakao`,
      {
        code,
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 네이버 로그인 요청
export const userNaverLogin = async (code: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/auth/naver`,
      {
        code,
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 구글 로그인 요청
export const userGoogleLogin = async (code: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/auth/google`,
      {
        code,
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 닉네임 중복 체크
export const userDisplayNameDuplicatedCheck = async (displayName: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/email/name-check`,
      { displayName }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    // return e?.message;
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 유저 프로필 조회
export const userProfileByUid = async (uid: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.get(
      `/user/profile/${uid}`,
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 유저 프로필 리스트 조회
export const userProfiles = async (profiles: string[]) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/user/profiles`,
      { profiles },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

type UserLeave = {
  uid: string;
  disabled: boolean;
  updateType: string;
};

// 유저 사용 중지
export const userDisabled = async ({
  uid,
  disabled = true,
  updateType,
}: UserLeave) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/user/disabled`,
      { uid, disabled, updateType },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 유저 사용 중지
export const userProfileChangeForSpace = async ({ postData }) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/user/profile-change-space`,
      { data: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * 디스코드 알림 전송 요청
 * @param {string} type  - 디스코드 서버명
 * @param {any} content - 보낼 데이터
 */
export const sendDiscordAlert = async (type: string, content: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/alert/discord`,
      { type, content }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data?.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * 카드 댓글 추가 이메일 전송 요청
 * @param {string} title - 댓글달린 카드 제목
 * @param {string} content - 댓글 콘텐츠
 * @param {string} member - 댓글단 멤버
 * @param {string} src - 콘텐츠 링크
 * @param {string[]} emails - 유저 이메일 목록
 *
 */
export const sendDiscussAlert = async (postData: any) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/alert/discuss`,
      { discuss: postData }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data?.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * 카드 확인요청 이메일 전송 요청
 * @param {string} spaceTitle - 확인요청 스페이스 제목
 * @param {string} cardTitle - 확인요청 카드 제목
 * @param {string} src - 콘텐츠 링크
 * @param {string[]} emails - 유저 이메일 목록
 *
 */
export const sendCardConfirm = async (postData: any) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/alert/confirm`,
      { card: postData }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data?.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * 카드 확인요청 이메일 전송 요청
 * @param {string} spaceTitle - 확인요청 스페이스 제목
 * @param {string} cardTitle - 확인요청 카드 제목
 * @param {string} src - 콘텐츠 링크
 * @param {string} status - 카드 상태
 * @param {string[]} emails - 유저 이메일 목록
 *
 */
export const sendCardFeedback = async (postData: any) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/alert/feedback`,
      { card: postData }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data?.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
