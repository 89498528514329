import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface Shape {
  shapeType: string;
  viewerList: any[];
  galleryList: any[];
}

// 보기모드 관리
const store = create<Shape>()(
  devtools((set, get) => ({
    shapeType: "default",
    setShapeType: (shapeType: string) =>
      set((state) => ({ shapeType: shapeType })),
    viewerList: [], // 카드별 파일 & 이미지 & 영상 뷰어 정보
    galleryList: [], // 전체 파일 & 이미지 & 영상 뷰어 정보
  }))
);

export default store;
