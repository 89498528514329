import axios, { AxiosInstance, AxiosResponse } from "axios";
import { TypeOfEvaluate } from "~/types";
import { useUserStore } from "../store";

/**
 *
 * 필터링
 * - NSFW, Slang Filter
 *
 * LLM(OpenAI)
 * - 텍스트 분석, 문장 생성, 키워드 추출, 요약
 *
 * Search(Vector Search, Full-Text Search)
 *
 * Storage
 * - Firebase & Azure & R2
 */

// Solomon API
const solomonAPIUri = import.meta.env.DEV
  ? `http://${import.meta.env.VITE_DEV_HOST}:1234`
  : import.meta.env.VITE_API_SERVER;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: solomonAPIUri,
  headers: {
    "X-IMPACTUS-CLIENT": import.meta.env.VITE_SECRET_KEY as string,
    "Content-Type": "application/json",
    Referrer: "impactus.kr",
  },
  timeout: 60000,
});

// NSFW 이미지 필터
export const mlFilterForNSFW = async (frm: FormData) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/ml/nsfw`,
      frm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 한글 비속어 필터
export const mlFilterForKorSlang = async (text: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/ml/slang?lang=kor`,
      { text },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 활동 기록 키워드 추출
export const mlActKeywords = async (postData: any[]) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/ml/keywords`,
      { data: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 댓글 기록 키워드 추출
export const mlDiscussKeywords = async (content: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/ml/keywords-discuss`,
      { content },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// GA 기반 카드 조회기록 확인
export const mlVisitRate = async ({
  sid,
  cid,
  amount = "year",
}: {
  sid: string;
  cid: string | undefined | null;
  amount: "year" | "month";
}) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/ml/analytics/visit-rate`,
      { sid, cid, amount },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * TODO: 학생 활동 기록 기반 관찰의견 초안 생성
 * @param {string} uid - user uid
 * @param {string} sid - space id
 * @param {TypeOfEvaluate.Evaluate} postData - 평가 결과 생성에 반영할 데이터
 * @returns
 */
export const llmGenerateObservation = async (
  uid: string,
  sid: string,
  postData: TypeOfEvaluate.Evaluate | null
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      "/llm//generate/observation",
      { uid, sid, data: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * 학생 활동 기록 기반 평가기준 초안 생성
 * @param {string} uid - user uid
 * @param {string} sid - space id
 * @param {string} postData - 평가 결과 생성에 반영할 데이터
 * @returns
 */
export const llmGenerateCriteria = async (
  uid: string,
  sid: string,
  postData: TypeOfEvaluate.Criteria | null
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      "/llm//generate/criteria",
      { uid, sid, data: postData },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * 학생 활동 기록 기반 탐구요약&주제생성
 * @param {string} uid - user uid
 * @param {string} sid - space id
 * @param {any} postData - 탐구 활동 데이터
 * @returns
 */
export const llmSummaryUserActivity = async (
  uid: string,
  sid: string,
  postData: any,
  model
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/llm/summary/activity`,
      { sid, uid, data: postData, model },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

/**
 * TODO: 학생 설문 기반 요약
 * @param {string} sid - space id
 * @param {string} survey - survey id
 * @returns
 */
export const llmSummaryUserSurvey = async (sid: string, survey: string) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      "/llm//summary/survey",
      {
        sid,
        survey,
      },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// R2 Storage Upload
export const uploadToR2Storage = async (frm, timestamp: number) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/storage/cf/blob?timestamp=${timestamp}`,
      frm,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );

    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// Typesense 데이터 추가
export const typesenseCreate = async (
  collection: string = "user",
  postData: any
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/typesense-api/create`,
      { collection, data: postData }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e: any) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// Typesense 데이터 가져오기
export const typesenseReads = async (
  collection: string = "user",
  query: any
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/typesense-api/reads`,
      { collection, query }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e: any) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// Typesense 업데이트
export const typesenseUpdate = async (
  collection: string = "user",
  id: string,
  postData: any
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/typesense-api/update`,
      { collection, id, data: postData }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e: any) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// Typesense 액션 삭제
export const typesenseDelete = async (
  collection: string = "user",
  id: string
) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/typesense-api/delete`,
      { collection, id }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e: any) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 유저 프로필 리스트 가져오기
export const userProfiles = async (profiles: string[]) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/user/profiles`,
      { profiles },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};

// 스페이스 복제
export const spaceCopyDataBackup = async (newCardList: any[]) => {
  try {
    const { status, data }: AxiosResponse = await axiosInstance.post(
      `/space/copy`,
      { newCardList },
      {
        headers: {
          Authorization: `Bearer ${useUserStore.getState()?.user?.uid || ""}`,
        },
      }
    );
    if (status >= 500 || data?.status !== "success") {
      return {
        status: "failure",
        msg: data?.msg?.message,
      };
    }
    return {
      status: "success",
      result: data.result,
    };
  } catch (e) {
    return {
      status: "failure",
      msg: e?.response?.data?.msg || "error",
    };
  }
};
