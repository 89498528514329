import { useState } from "react";
import { Box, Typography, Grid, Badge, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { GradientTypography } from "~/components/common/IPTypo";
import useBaseStore from "~/libs/store/base";
import HomeLayout from "~/layouts/home/HomeLayout";
import { styled } from "@mui/material/styles";
import { renderHTML } from "~/libs/utils";
import { FirebaseLogger } from "~/libs/ga";
import { useNavigate } from "react-router-dom";

interface IState {
  data: {
    src: string;
    isSignature: boolean;
    className: string;
    title: string;
    content: string;
    moTitle?: string;
    moContent?: string;
  }[];
}

const Curriculum = () => {
  const [state, setState] = useState<IState>({
    data: [
      {
        src: "https://static.jazzy.kr/curriculum/impact_class.jpeg",
        className: "임팩트메이커 클래스",
        title: "사회 문제 해결, 전공 심화 학습과<br/> 관련된 탐구 수업",
        moTitle: "사회 문제 해결, 전공 심화 학습과 관련된<br/> 탐구 수업",
        isSignature: true,
        content:
          "학생 개인의 진로와 연계하여 탐구 주제 선정, 배경 및 필요성,<br/>방법 설계 등 구체적인 탐구 과정을 배우는 수업이에요.",
        moContent:
          "학생 개인의 진로와 연계하여 탐구 주제 선정, 배경 및 필요성, 방법 설계 등 구체적인 탐구 과정을 배우는 수업이에요.",
      },
      {
        src: "https://static.jazzy.kr/curriculum/science_experiment.png",
        className: "바이오 실험 클래스",
        title: "실험의 목적과 응용 방안에<br/> 중점을 둔 자기주도적 실험 수업",
        moTitle:
          "실험의 목적과 응용 방안에 중점을 둔<br/> 자기주도적 실험 수업",
        isSignature: false,
        content:
          "분광광도계 실험, 발효과학 실험 등 교과 수업에서 심화한 실험으로,<br/>이론, 실습, 보고서 작성 및 발표 등이 포함된 수업이에요.",
        moContent:
          "분광광도계 실험, 발효과학 실험 등 교과 수업에서 심화한 실험으로, 이론, 실습, 보고서 작성 및 발표 등이 포함된 수업이에요.",
      },
      {
        src: "https://static.jazzy.kr/curriculum/climate_change.jpeg",
        className: "기후위기 대응 클래스",
        // title:
        //   "기후위기를 이해하고 학생들의 진로와<br/>연계한 대응 방안탐구 수업",
        // moTitle:
        //   "기후위기를 이해하고 학생들의 진로와 연계한 대응 방안탐구 수업",
        title:
          "기후위기를 이해하고 학생의 진로와<br/>연계하여 대응 방안을 탐구하는 수업",
        moTitle:
          "기후위기를 이해하고 학생의 진로와 연계하여 대응 방안을 탐구하는 수업",
        isSignature: false,
        content:
          "기후변화 정의 및 개념 학습, 프로젝트 활동, 토의 및 에세이 작성 등<br/>기후위기에 대응하는 방법을 배우는 수업이에요.",
        moContent:
          "기후변화 정의 및 개념 학습, 프로젝트 활동, 토의 및 에세이 작성 등 기후위기에 대응하는 방법을 배우는 수업이에요.",
      },
      {
        src: "https://static.jazzy.kr/curriculum/data_science_v2.png",
        className: "데이터 사이언스 클래스",
        // title:
        //   "코딩과 통계 교과 응용을 통해 데이터에<br/> 기반한 탐구 방법을 배우는 수업",
        // moTitle:
        //   "코딩과 통계 교과 응용을 통해 데이터에<br/> 기반한 탐구 방법을 배우는 수업",
        title:
          "통계 교과 내용을 심화하여 데이터에<br/> 기반한 탐구 방법을 배우는 수업",
        moTitle:
          "통계 교과 내용을 심화하여 데이터에<br/> 기반한 탐구 방법을 배우는 수업",
        isSignature: false,
        content:
          "파이썬, 엑셀, 구글 루커 스튜디오 등을 활용해서<br/>데이터 전처리, 분석, 시각화하는 전 과정을 다루는 수업이에요.",
        moContent:
          "파이썬, 엑셀, 구글 루커 스튜디오 등을 활용해서 데이터 전처리, 분석, 시각화하는 전 과정을 다루는 수업이에요.",
      },
    ],
  });
  const isTablet = useBaseStore((state) => state.isTablet);
  const isMobile = useBaseStore((state) => state.isMobile);
  const navigate = useNavigate();

  const onHandleMove = () => {
    FirebaseLogger("click", { event: `홈-클래스-도입문의` });
    navigate("/course/form");
  };

  const checkOrderLeft = (index: number) => {
    if (isMobile) return 1;
    if (index === 1 || index === 3) {
      return 2;
    }
    return 1;
  };

  const checkOrderRight = (index: number) => {
    if (isMobile) return 2;
    if (index === 1 || index === 3) {
      return 1;
    }
    return 2;
  };

  return (
    <HomeLayout>
      <StyledTitleWrap>
        <Typography component="h3" variant="h3" color="secondary">
          다양한 형태의 교육 커리큘럼을
          <br />
          <GradientTypography title="원하는 방식" />
          으로 진행할 수 있어요.
        </Typography>
      </StyledTitleWrap>
      <StyledRoot length={state.data.length}>
        {state.data.map((item, index) => {
          return (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ order: checkOrderLeft(index) }}
              >
                <StyledContent>
                  <img src={item.src} alt={item.title} />
                </StyledContent>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ order: checkOrderRight(index) }}
              >
                <StyledContent
                  className={`text-content ${
                    (index === 1 || index === 3) && !isMobile && `text-left`
                  }`}
                >
                  {item.isSignature ? (
                    <Badge
                      color="error"
                      badgeContent="시그니처"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <StyledContentClassName component="h6">
                        {item.className}
                      </StyledContentClassName>
                    </Badge>
                  ) : (
                    <StyledContentClassName component="h6">
                      {item.className}
                    </StyledContentClassName>
                  )}
                  <StyledContentTitle>
                    {renderHTML(isMobile ? item.moTitle : item.title)}
                  </StyledContentTitle>
                  <StyledContentText>
                    {renderHTML(isMobile ? item.moContent : item.content)}
                  </StyledContentText>
                </StyledContent>
              </Grid>
            </Grid>
          );
        })}
      </StyledRoot>
      <Box
        sx={{
          textAlign: "center",
          pt: (theme) => (isMobile ? theme.spacing(3) : theme.spacing(10)),
          maxWidth: 200,
          margin: "0 auto",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          size="large"
          sx={{ mt: (theme) => theme.spacing(0.25) }}
          onClick={onHandleMove}
          endIcon={<ArrowForward />}
        >
          <Typography fontWeight={500}>도입 문의하기</Typography>
        </Button>
      </Box>
    </HomeLayout>
  );
};

const StyledTitleWrap = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  "& h3": {
    textAlign: "center",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: { fontSize: theme.typography.h5.fontSize },
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledRoot = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "length",
})<{ length?: number }>(({ theme, length }) => ({
  paddingTop: 50,
  "& > div": {
    maxWidth: 1200,
    margin: "0 auto",
    paddingBottom: theme.spacing(10),
  },
  [`& > div:nth-child(${length || 3})`]: {
    paddingBottom: 0,
  },
  [theme.breakpoints.down("lg")]: {
    "& > div": { maxWidth: "95%" },
  },
  [theme.breakpoints.down("md")]: { paddingTop: 15, paddingBottom: 0 },
  [theme.breakpoints.down("sm")]: {
    "& > div": {
      maxWidth: "95%",
      paddingBottom: theme.spacing(1.5),
    },
  },
}));

const StyledContent = styled(Box)(({ theme }) => ({
  "& img": {
    maxWidth: "100%",
    height: 380,
    // border: `2px solid ${theme.palette.info.dark}`,
    borderRadius: 5,
  },
  "& .MuiBadge-badge": {
    left: 30,
    top: -2,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: 600,
  },
  padding: theme.spacing(2),
  "&.text-content": {
    marginTop: theme.spacing(3),
  },
  "&.text-left": {
    position: "relative",
    left: 50,
  },
  [theme.breakpoints.down("lg")]: {
    "&.text-left": {
      position: "relative",
      left: 0,
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    "& img": { height: "auto" },
    "&.text-content": {
      marginTop: 0,
    },
  },
}));

const StyledContentClassName = styled(Typography)(({ theme }) => ({
  background: theme.palette.info.light,
  fontSize: theme.typography.h6.fontSize,
  padding: theme.spacing(1),
  borderRadius: 5,
  display: "inline-block",
  fontWeight: 600,
  color: theme.palette.info.dark,
}));

const StyledContentTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3),
  fontWeight: 700,
  fontSize: theme.typography.h4.fontSize,
  lineHeight: "125%",
  [theme.breakpoints.down("lg")]: {
    "& br": { display: "none" },
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.h6.fontSize,
  },
}));

const StyledContentText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontWeight: 400,
  fontSize: theme.typography.h6.fontSize,
  lineHeight: "125%",
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.subtitle2.fontSize,
  },
}));

export default Curriculum;
