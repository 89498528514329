import { useState, useEffect } from "react";
import { asyncSafe, loadScriptWithKey, renderHTML } from "~/libs/utils";
import { useUserStore, useBaseStore } from "~/libs/store";
import useAuthState from "~/hooks/useAuthState";
import { auth, User } from "~/libs/db/firebase";
import { setUserByAnonym, userSetByUid } from "~/libs/db/user";
import {
  setUserIdByAnalytics,
  setUserPropertiesByAnalytics,
  getUserDeviceLog,
} from "~/libs/ga";
import { Loading } from "./Loading";
import { BrowserMessage } from "~/components/alert/AlertMessage";
import { Alert, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Close } from "@mui/icons-material";

import { ipAPI } from "~/libs/api";
import { IPType } from "~/libs/api/ip";
import { LOCAL_STORAGE_ENUM } from "~/libs/constants";
import ChannelService from "~/utils/channel";

import { Toaster, toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import useNetworkState from "react-use/lib/useNetworkState";
import useEffectOnce from "react-use/lib/useEffectOnce";
import useLocalStorage from "react-use/lib/useLocalStorage";

export default function Core() {
  const [user, loading, error] = useAuthState(auth); // Firebase auth
  const dataLoading = useBaseStore((state) => state.isLoading); // 전체 데이터 로딩
  const userData = useUserStore((state) => state.user); // 유저
  const isSignIn = useUserStore((state) => state.isSignIn); // 로그인 IP 저장

  const userInit = async (user: User) => {
    if (!user?.email) {
      setUserByAnonym(user.uid);
      return;
    }
    await asyncSafe(async () => {
      await userSetByUid(user);
      await setUserIdByAnalytics(user.uid);
      await setUserPropertiesByAnalytics();
      useUserStore.setState({ isAnonymous: false });
    }, "유저 정보를 불러오는데 오류가 있어요.<br/>잠시 후에 다시 시도해 주세요.");
  };

  useEffect(() => {
    if (!loading && !error) {
      if (user) {
        userInit(user)
          .then(() => {})
          .catch((e) => {});
      } else {
        useUserStore.setState({
          user: null,
          userAuth: null,
          isAnonymous: false, // 로그아웃 되면 처리!
        });
      }
    }
    return () => {};
  }, [loading, error, user]);

  useEffect(() => {
    if (userData && isSignIn) {
      useUserStore.setState({ isSignIn: false });
      // TODO: 신규 로그인 IP 업로드
      // userSignInIpAdd(userData.uid);
    }
    return () => {};
  }, [userData, isSignIn]);

  return (
    <>
      {dataLoading && <Loading />}
      <NotifyState />
      <NetworkState />
      <UIState />
      <ChannelTalkState />
      <CalcLocalStorage />
      <LoggingForMobile />
      {/* <MessagingChecker /> */}
    </>
  );
}

// 네트워크 상태 체크
const NetworkState = () => {
  const { effectiveType: connection, online: isOnline } = useNetworkState(); // slow-2g, 2g, 3g, 4g

  useEffect(() => {
    if (!isOnline) {
      useBaseStore.setState({ networkState: "offline" });
      useBaseStore.setState({
        notify: {
          message:
            "네트워크 연결이 불가한 상태예요.<br/>서비스가 정상 동작하지 않아요.",
          option: { vertical: "top", horizontal: "center" },
        },
      });
      return;
    }
    if (connection) {
      useBaseStore.setState({ networkState: connection });
      if (connection === "2g" || connection === "slow-2g") {
        useBaseStore.setState({
          notify: {
            message:
              "저속 네트워크 연결 상태예요.<br/>일부 서비스가 정상 동작하지 않을 수 있어요.",
            option: { vertical: "top", horizontal: "center" },
          },
        });
      }
    }
    return () => {};
  }, [connection, isOnline]);

  return null;
};

// UI 체크
const UIState = () => {
  const [message, setMessage] = useState("");
  const isMobile = useMediaQuery("(max-width: 767px)"); // 모바일 여부
  const isMinTablet = useMediaQuery("(max-width: 900px)"); // 태블릿 세로 여부
  const isMinPC = useMediaQuery("(min-width: 1300px)"); // pc 너비 체크
  const isLandscape = useMediaQuery("(orientation: landscape)"); // ore react-use 사용 랜드스케이프

  useEffect(() => {
    const device = getUserDeviceLog();
    useBaseStore.setState({ device }); // 기기 정보 저장
    if (isMinTablet) {
      //  || device.deviceType?.toLocaleLowerCase() === "tablet"
      useBaseStore.setState({ isTablet: true });
    }

    if (
      isMinPC &&
      !device.deviceType?.includes("tablet") &&
      !device?.browserName?.toLowerCase?.()?.includes("chrome")
    ) {
      setMessage(`임팩터스 사이트는 크롬 브라우저에 최적화되어 있어요.`);
    }

    return () => {};
  }, [isMinTablet]);

  // PWA 체크
  useEffect(() => {
    const checkStandAlone = () => {
      const isPWA = ["fullscreen", "standalone", "minimal-ui"].filter(
        (displayMode) =>
          window?.matchMedia("(display-mode: " + displayMode + ")")?.matches
      );
      if (isPWA?.length > 0 || window?.location?.search?.includes("pwa")) {
        useBaseStore.setState({ isStandalone: true });
      }
    };
    window.addEventListener("DOMContentLoaded", checkStandAlone);

    return () => {
      window.removeEventListener("DOMContentLoaded", checkStandAlone);
    };
  }, []);

  // 모바일 너비 체크
  useEffect(() => {
    useBaseStore.setState({ isMobile });
  }, [isMobile]);

  // landscape 체크
  useEffect(() => {
    useBaseStore.setState({ isLandscape });
  }, [isLandscape]);

  const browserCheck = () => {
    setMessage("");
  };

  if (!message) return null;
  return <BrowserMessage message={message} onClose={browserCheck} />;
};

// 공통 notify & alert
const NotifyState = () => {
  const isMobile = useMediaQuery("(max-width: 767px)"); // 모바일 여부
  const notify = useBaseStore((state) => state.notify); // 시스템 알림
  const theme = useTheme();
  // if (!notify || notify.message === "") return null;

  useEffect(() => {
    if (!notify || notify?.message === "") return;
    toast.custom((t) => (
      <Alert
        style={{
          position: "relative",
          zIndex: 9999,
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          borderRadius: 5,
          border: `1px solid ${theme.palette.primary.dark}`,
        }}
        severity={notify.type || "error"}
        action={
          <Close
            fontSize="small"
            onClick={() => toast.dismiss(t)}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: 3,
              right: 3,
              color: theme.palette.secondary.dark,
            }}
          />
        }
      >
        <Typography
          component="p"
          variant="subtitle1"
          color="secondary.dark"
          sx={{ lineHeight: "120%", fontWeight: 400 }}
        >
          {renderHTML(notify.message)}
        </Typography>
        {notify?.onClick && (
          <Button
            variant="contained"
            color="secondary"
            onClick={notify?.onClick || undefined}
          >
            보기
          </Button>
        )}
      </Alert>
    ));
  }, [notify]);

  return (
    <Toaster
      expand
      visibleToasts={10}
      duration={3000}
      position={isMobile ? "bottom-center" : "bottom-right"}
    />
  );
};

// 로컬 브라우저 DB 사용량 체크
const CalcLocalStorage = () => {
  useEffect(() => {
    calcLocalStorage()
      .then(() => {})
      .catch((e) => {
        console.log("storage calc e: ", e);
      });
  }, [navigator?.storage]);

  // REF: https://han41858.tistory.com/54
  const calcLocalStorage = async () => {
    if (navigator?.storage && navigator?.storage?.estimate) {
      const quota = await navigator.storage.estimate();
      // quota.usage: 사용 중인 용량(byte) %
      // quota.quota: 앞으로 사용할 수 있는 전체 용량(byte)
      const percentageUsed = (quota.usage / quota.quota) * 100;
      const remaining = quota.quota - quota.usage;
      useBaseStore.setState({
        localStorageUsage: { percentageUsed, remaining },
      });
    }
  };

  return null;
};

// url-format 클릭 이벤트 처리
export const URLFormatChecker = () => {
  const onHandleURlFormat = (event: any) => {
    event?.stopPropagation();
    event?.preventDefault();
    const target = event?.currentTarget;
    const url = target?.innerText;
    window.open(url, "_blank");
    return;
  };

  useEffect(() => {
    const urlFormatList = document.querySelectorAll(".url-format");
    [].forEach.call(urlFormatList, (hash) => {
      hash.addEventListener("click", onHandleURlFormat, false);
    });
    return () => {
      [].forEach.call(urlFormatList, (hash) => {
        hash.removeEventListener("click", onHandleURlFormat, false);
      });
    };
  }, []);

  return null;
};

interface IStateChannelTalk {
  isBooted: boolean;
}

// NOTE: 채널톡
const ChannelTalkState = () => {
  // TODO: url 주소에 따라 채널톡 미노출 처리!
  const [state, setState] = useState<IStateChannelTalk>({
    isBooted: false,
  });
  const location = useLocation();

  useEffectOnce(() => {
    ChannelService.boot(() => {
      console.log("channel talk booted");
      setState((prev) => ({ ...prev, isBooted: true }));
    });
  });

  useEffect(() => {
    if (!state.isBooted) return;
    if (location.pathname) {
      if (
        location.pathname.includes("/space") ||
        location.pathname.includes("/space/board") ||
        location.pathname.includes("/login") ||
        location.pathname.includes("/festival") ||
        location.pathname.includes("/analyze") ||
        location.pathname.includes("/roadmap") ||
        location.pathname.includes("/coach") ||
        location.pathname.includes("/lecture") ||
        location.pathname.includes("/share/slide")
      ) {
        ChannelService.hideChannelButton();
      } else {
        ChannelService.showChannelButton();
      }
    }
  }, [state.isBooted, location.pathname]);

  return null;
};

// 모바일에서 로깅 보기
const LoggingForMobile = () => {
  useEffectOnce(() => {
    const device = getUserDeviceLog();
    if (import.meta.env.DEV || location.host?.includes("192.168.0.127")) {
      if (
        device.deviceType?.includes("tablet") ||
        device.deviceType?.includes("mobile")
      ) {
        loadScriptWithKey(
          "eruda",
          "//cdn.jsdelivr.net/npm/eruda" as string,
          () => {
            const { eruda } = window as any;
            if (eruda) eruda.init();
          }
        );
      }
    }
  });

  return null;
};

// 최초 접속 IP 체크
const connectIPState = () => {
  const [state, setState] = useState({ isInit: false });
  const navigate = useNavigate();
  // 허용국가 - 일본, 미국, 베트남, 필리핀, 싱가포르
  const [userIpCountry, setUserIpCountry] = useLocalStorage(
    LOCAL_STORAGE_ENUM.USER_IP_COUNTRY,
    ""
  );

  useEffect(() => {
    if (!state.isInit) {
      setState((prev) => ({ ...prev, isInit: true }));
      init()
        .then(() => {})
        .catch((e) => {
          console.log("connect ip e: ", e);
        });
    }
  }, [state.isInit]);

  const init = async () => {
    if (userIpCountry && userIpCountry !== "KR") {
      navigate("/block");
      return;
    }
    const ipInfo = (await ipAPI.getIp()) as IPType;
    if (ipInfo && ipInfo?.country) {
      if (ipInfo.country !== "KR") {
        setUserIpCountry(ipInfo.country);
        navigate("/block");
      }
    }
  };

  return null;
};

// const onMessageListener = (messaging: any) =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });

// interface IMessagingState {
//   isSupport: boolean;
//   permission: string;
//   isClose: boolean;
// }

// // FCM 메시징 상태 체크
// const MessagingChecker = () => {
//   const [state, setState] = useState<IMessagingState>({
//     isSupport: false,
//     permission: "",
//     isClose: false,
//   });
//   const location = useLocation();
//   const user = useUserStore((state) => state.user);
//   const isMobile = useBaseStore((state) => state.isMobile);

//   useEffect(() => {
//     if (user && !user?.pushToken) {
//       if (location.pathname && location.pathname.includes("/space/board")) {
//         console.log("pushToken init");
//         init()
//           .then(() => {})
//           .catch((e) => {});
//       }
//     }
//   }, [user, location?.pathname]);

//   const init = async () => {
//     const isSupport = await isMessagingSupported();
//     setState((prev) => ({ ...prev, isSupport }));
//   };

//   // NOTE: 푸시토큰 설정 동의 granted, denied 확인 필요
//   const requestPushToken = async () => {
//     try {
//       const currentToken = await getToken(messaging, {
//         vapidKey: import.meta.env.VITE_FCM_KEY,
//       });
//       return currentToken;
//     } catch (e) {
//       console.log("request messaging token e: ", e);
//       return null;
//     }
//   };

//   const setNotificationPermission = async () => {
//     if (!user) return;
//     await asyncSafe(
//       async () => {
//         const permission = await Notification.requestPermission();
//         if (permission !== "granted") {
//           useBaseStore.setState({
//             notify: {
//               message:
//                 "알림 등록 설정이 거부됐어요.<br/>설정에서 변경할 수 있어요.",
//             },
//           });
//           return;
//         }
//         const pushToken = await requestPushToken();
//         if (pushToken) {
//           await userUpdate(user.uid, { pushToken });
//           useUserStore.setState({ user: { ...user, pushToken } });
//           await registeredMessagingService();
//         }
//       },
//       "알림을 등록하는데 오류가 있어요.<br/>잠시 후에 다시 시도해 주세요.",
//       false,
//       async () => {
//         setState((prev) => ({ ...prev, isClose: true }));
//       },
//       "토큰-웹-등록"
//     );
//   };

//   // Foreground Service 등록
//   const registeredMessagingService = async () => {
//     onMessageListener(messaging)
//       .then((payload: any) => {
//         const title = payload?.notification?.title;
//         const body = payload?.notification?.body;
//         const data = payload?.data;
//         useUserStore.setState({
//           messaging: {
//             ...useUserStore.getState().messaging,
//             show: true,
//             title,
//             body,
//             data,
//           },
//         });
//       })
//       .catch((e) => {
//         console.log("registered messaging e: ", e);
//       });
//   };

//   // 닫기
//   const onHandleClose = () => {
//     setState((prev) => ({ ...prev, isClose: true }));
//   };

//   if (
//     !state.isSupport ||
//     !user ||
//     state.permission !== "" ||
//     isMobile ||
//     state.isClose ||
//     user?.pushToken
//   )
//     return null;

//   return (
//     <Alert
//       style={{
//         position: "fixed",
//         zIndex: 9999,
//         top: 0,
//         left: "50%",
//         transform: "translateX(-50%)",
//         width: 400,
//         paddingTop: 5,
//         paddingBottom: 5,
//         borderRadius: 5,
//         border: `1px solid #141414`,
//       }}
//       severity={"info"}
//       action={
//         <Close
//           fontSize="small"
//           onClick={onHandleClose}
//           sx={{ cursor: "pointer" }}
//         />
//       }
//     >
//       <Typography
//         component="p"
//         variant="subtitle1"
//         color="secondary.dark"
//         sx={{ lineHeight: "120%", fontWeight: 400 }}
//       >
//         사이트에서도 알림을 받을 수 있어요.
//       </Typography>
//       <Button
//         variant="contained"
//         color="info"
//         size="small"
//         sx={{ mt: (theme) => theme.spacing(1) }}
//         onClick={setNotificationPermission}
//       >
//         알림 받기
//       </Button>
//     </Alert>
//   );
// };
