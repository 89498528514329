import { getUserProfile } from "~/libs/ga";
import { TypeOfSpace } from "~/types";
import { useSpaceStore, useUserStore } from "./store";
import { isInGroupUser } from "~/data/space/constants";

// NOTE: 스페이스 권한 관리

// 관리자인지 체크
export const isAdminPermission = () => {
  const user = getUserProfile();
  if (user.type === -1) return true;
  return false;
};

// 스페이스 임팩터스 관리자 & 소유자 & owner 권한을 갖는 참여자
export const isSpaceOwnPermission = (space: TypeOfSpace.Space) => {
  const user = getUserProfile();
  if (user?.type === -1) return true;
  if (space?.uid === user?.uid) return true;
  const findPermissionUser = space?.permissionUser?.find?.(
    (p) => p?.uid === user?.uid
  );
  if (findPermissionUser?.type === "owner") return true;
  return false;
};

// 카드 권한 설정 확인
// - 스페이스 관리자가 아니면서, 카드 수정 불가
export const isSpaceCardEditing = (space: TypeOfSpace.Space) => {
  const user = getUserProfile();
  return space?.uid !== user?.uid && !space?.setting?.allowCardEditing;
};

// 익명 로그인 유저 여부 확인
export const isAnonymousUser = () => {
  const isAnonymous = useUserStore.getState().isAnonymous;
  return isAnonymous;
};

interface ICheckBoardBlind {
  board: TypeOfSpace.SpaceColumnsBoard; // 보드 정보
  cardUid: string; // 카드 소유자 uid
}

// 내용 숨김 처리 여부 권한 체크
export const checkIsBoardBlindContent = ({
  board,
  cardUid,
}: ICheckBoardBlind): boolean => {
  const space = useSpaceStore.getState()?.selectedSpace;
  const profile = getUserProfile();

  if (!board?.blind?.content) return true;
  if (isSpaceOwnPermission(space)) return true;
  // 그룹 설정 & 같은 그룹원
  if (board?.blind?.group_edit && isInGroupUser(cardUid, profile?.uid)) {
    return true;
  }

  return cardUid === profile?.uid;
};
